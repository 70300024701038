import { Reducer } from 'react'
import { Node } from './types/Question'
import { NodeState } from './types/State'

export const CURRENT_QUESTION_ACTION_UPDATE = 'CURRENT_QUESTION_ACTION_UPDATE'
export const QUESTION_ACTION_INIT = 'QUESTION_ACTION_INIT'
export const QUESTION_ACTION_ANSWER = 'QUESTION_ACTION_ANSWER'

interface CurrentQuestionActionUpdate {
    type: typeof CURRENT_QUESTION_ACTION_UPDATE
    current: Node | null
}

interface QuestionActionInit {
    type: typeof QUESTION_ACTION_INIT
    questions: Node | null
}

export type QuestionActionTypes = CurrentQuestionActionUpdate | QuestionActionInit

export const nodeReducer: Reducer<NodeState, QuestionActionTypes> = (
    nodeState: NodeState,
    action: QuestionActionTypes
) => {
    // console.log(action)
    switch (action.type) {
        case CURRENT_QUESTION_ACTION_UPDATE:
            nodeState.current = action.current
            return { ...nodeState }
        case QUESTION_ACTION_INIT:
            nodeState.questions = action.questions
            if (action.questions) {
                nodeState.current = action.questions
            }
            return { ...nodeState }
        default:
            return { ...nodeState }
    }
}
