import { useContext } from 'react'
import { appContext } from '../state/AppContext'
import { Node } from '../state/types/Question'

const useNodes = (): {
    questions: Node | null
    current: Node | null
    numberOfStepsLongestPath: (node: Node) => number
} => {
    const { state } = useContext(appContext)

    const numberOfStepsLongestPath = (node: Node): number => {
        if (node.children && node.children.length > 0) {
            let highest = 0
            for (const child of node.children) {
                highest = Math.max(1 + numberOfStepsLongestPath(child), highest)
            }
            return highest
        }

        return 0
    }

    return {
        questions: state.questionState.questions,
        current: state.questionState.current,
        numberOfStepsLongestPath,
    }
}

export default useNodes
