import { Reducer } from 'react'
import { Status } from './types/Status'

export const STATUS_ACTION_CHANGE = 'STATUS_ACTION_CHANGE'

interface StatusActionChange {
    type: typeof STATUS_ACTION_CHANGE
    status: Status
}

export type StatusActionTypes = StatusActionChange

export const statusReducer: Reducer<Status, StatusActionTypes> = (status: Status, action: StatusActionTypes) => {
    switch (action.type) {
        case STATUS_ACTION_CHANGE:
            status = action.status
            return status
        default:
            return status
    }
}
