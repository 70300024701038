import React, { useEffect, useReducer } from 'react'
import { nodeReducer, QuestionActionTypes, QUESTION_ACTION_INIT, CURRENT_QUESTION_ACTION_UPDATE } from './nodeReducer'
import { statusReducer, StatusActionTypes, STATUS_ACTION_CHANGE } from './statusReducer'
import { AppState, defaultState } from './types/State'
import questionLoader from '../api/Loader'
import { Status } from './types/Status'
import { useTranslation } from 'react-i18next'

export type ActionTypes = StatusActionTypes | QuestionActionTypes

export interface AppContext {
    state: AppState
    dispatch: React.Dispatch<ActionTypes>
}

const appContext = React.createContext<AppContext>({
    state: defaultState,
    dispatch: () => null,
})

const mainReducer = (state: AppState, action: ActionTypes): AppState => {
    // console.log('the reducer')
    return {
        status: statusReducer(state.status, action as StatusActionTypes),
        questionState: nodeReducer(state.questionState, action as QuestionActionTypes),
    }
}

interface AppProviderProps {
    url: string
    children: React.ReactNode
}

const AppProvider = (props: AppProviderProps) => {
    const { i18n } = useTranslation()

    useEffect(() => {
        function handleMessage(event: MessageEvent) {
            //TODO origin check

            if (event.data) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (event.data['message'] === 'treedata') {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
                    const questions = JSON.parse(event.data['data'])

                    if (questions) {
                        dispatch({
                            type: QUESTION_ACTION_INIT,
                            questions: questions,
                        })
                        dispatch({
                            type: CURRENT_QUESTION_ACTION_UPDATE,
                            current: questions,
                        })
                        dispatch({
                            type: STATUS_ACTION_CHANGE,
                            status: Status.READY,
                        })
                    }
                }
            }
        }

        window.addEventListener('message', handleMessage)
        return () => window.removeEventListener('message', handleMessage)
    }, [])

    useEffect(() => {
        const fetchQuestions = async () => {
            const questions = await questionLoader(props.url)
            if (questions) {
                dispatch({
                    type: QUESTION_ACTION_INIT,
                    questions: questions,
                })
                dispatch({
                    type: CURRENT_QUESTION_ACTION_UPDATE,
                    current: questions,
                })
                dispatch({
                    type: STATUS_ACTION_CHANGE,
                    status: Status.READY,
                })
            }
        }

        const searchParams = new URLSearchParams(window.location.search)
        const preview = searchParams.get('preview')
        const lang = searchParams.get('lang')

        if (!preview) {
            fetchQuestions().catch((e) => {
                console.log(e)
            })
        }

        if (lang) {
            void i18n.changeLanguage(lang)
        }
    }, [props.url])

    // useQuestionLoader(props.url)
    // const initialState = { ...defaultState, questionState: { questions: questions, current: null } }

    const [state, dispatch] = useReducer(mainReducer, defaultState)
    return <appContext.Provider value={{ state, dispatch }}>{props.children}</appContext.Provider>
}

export { AppProvider, appContext }
