import React from 'react'

export interface PanelProps {
    children?: React.ReactNode
    className?: string
}

const QuestionArea: React.FC<PanelProps> = ({ children, className = '' }) => (
    <div className="js">
        <div className="content-small-wrapper QuestionArea_container">{children}</div>
    </div>
)

export default QuestionArea
