import React, { useState } from 'react'
import { Icon, RichText } from '@sigbar-agency/components'

export interface CollapseProps {
    title: string
    content: string
    isOpen?: boolean
    id: string
    isSmall?: boolean
}

const Collapse: React.FC<CollapseProps> = ({ title, content, isOpen = false, id, isSmall = false }) => {
    const [open, setOpen] = useState(isOpen)

    if (title === null || content === null) {
        return null
    }

    return (
        <div className="collapse">
            <div className="header">
                <h3>
                    <button
                        onClick={() => setOpen(!open)}
                        aria-expanded={open}
                        aria-controls={`collapse-panel-${id}`}
                        className="collapse-header"
                    >
                        <Icon name={open ? 'minus' : 'plus'} className="icon" />
                        <span className={`text ${isSmall ? 'small' : ''}`}>{title}</span>
                    </button>
                </h3>
            </div>
            <div id={`collapse-panel-${id}`} className={`panel${open ? '' : ' hidden'}`}>
                <div className="panelContent">
                    <RichText content={content.replace(/style="(.*?)"/gm, '')} />
                </div>
            </div>
        </div>
    )
}

export default Collapse
