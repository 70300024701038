import React from 'react'

interface CssLinkProps {
    cssfile?: string
}

export const CssLink = (props: CssLinkProps): JSX.Element | null => {
    const { cssfile } = props

    if (!cssfile) {
        return null
    }

    return <link rel="stylesheet" href={cssfile} />
}
