import { Node } from './Question'
import { Status } from './Status'

export interface NodeState {
    questions: Node | null
    current: Node | null
}

export interface AppState {
    status: Status
    questionState: NodeState
}

export const defaultState: AppState = {
    status: Status.LOADING,
    questionState: {
        questions: null,
        current: null,
    },
}
