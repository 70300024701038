import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing translation files
import translationNL from './assets/locales/nl/common.json'
import translationEN from './assets/locales/en/common.json'
import translationES from './assets/locales/es/common.json'
import translationFR from './assets/locales/fr/common.json'

//Creating object with the variables of imported translation files
const resources = {
    nl: {
        translation: translationNL,
    },
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    es: {
        translation: translationES,
    },
}

//i18N Initialization

const getLanguage = () => {
    const htmlLang = document.documentElement.lang.substring(0, 2)
    const mainLang = document.getElementsByTagName('main').item(0)?.lang.substring(0, 2)
    console.log('HTML', htmlLang)
    console.log('MAIN', mainLang)

    if (htmlLang === 'en') {
        if (mainLang === 'es' || mainLang === 'fr') {
            return mainLang
        }
        return 'en'
    }
    if (htmlLang === 'es' || htmlLang === 'fr' || htmlLang === 'nl') {
        return htmlLang
    }

    return 'nl'
}

i18n.use(initReactI18next)
    .init({
        resources,
        lng: getLanguage(),
        debug: false,
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
    })
    .then()
    .catch(() => console.log())

export default i18n
