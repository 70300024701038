import React from 'react'
import App from './App'
import './i18n'
import { CssLink } from './components/elements/CssLink'

interface rootProps {
    cssfile: string
    download: string
}

export default function Root(props: rootProps): JSX.Element {
    return (
        <React.StrictMode>
            <div className="nw-visumwijzer">
                <CssLink cssfile={props.cssfile} />
                <App questionfile={props.download} />
            </div>
        </React.StrictMode>
    )
}
