import React from 'react'

export interface PanelProps {
    children?: React.ReactNode
    className?: string
}

const ResultArea: React.FC<PanelProps> = ({ children, className = '' }) => (
    <div className="js">
        <div className="content-small-wrapper ResultArea_container">
            <div className="ResultsArea_results">{children}</div>
        </div>
    </div>
)

export default ResultArea
