import React from 'react'
import ReactDOM from 'react-dom/client'
import r2wc from 'react-to-webcomponent'
import Root from './root'

const WebVisumwijzer = r2wc(Root, React, ReactDOM, {
    props: {
        cssfile: 'string',
        download: 'string',
    },
})

customElements.define('nw-visumwijzer', WebVisumwijzer)

export default this
