import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { appContext } from '../../../state/AppContext'
import { CURRENT_QUESTION_ACTION_UPDATE } from '../../../state/nodeReducer'
import { Question, Node, AnswerOption, HelpText, Result } from '../../../state/types/Question'
import useNodes from '../../../hooks/useNodes'
import { Button, ProgressBar } from '@sigbar-agency/components'
import { InputRadioButtons, InputSelect } from '@sigbar-agency/form-components'
import { Autocomplete } from 'form-components-two'
import QuestionArea from '../../elements/QuestionArea'
import ButtonGroup from '../../elements/ButtonGroup'
import ResultArea from '../../elements/ResultArea'
import Loader from '../../elements/Loader'
import Collapse from '../../elements/Collapse'

const MainPage: React.FC = () => {
    const { t, i18n } = useTranslation()
    const { dispatch } = useContext(appContext)
    const { questions, current, numberOfStepsLongestPath } = useNodes()
    const [answer, setAnswer] = useState<AnswerOption | null>()
    const [nextQuestion, setNextQuestion] = useState<Node | null>()
    const [prevAnswers, setPrevAnswers] = useState<{ question: Node; answer: AnswerOption }[]>([])
    const [radioValue, setRadioValue] = useState<string>('')
    const results = current?.results && current?.results.length > 0
    const lang = i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1) || 'Nl'

    const handlePrevClick = () => {
        const array = [...prevAnswers]
        const item = array.pop()
        setPrevAnswers(array)
        setAnswer(null)

        if (item) {
            setNextQuestion(item.question)
            dispatch({
                type: CURRENT_QUESTION_ACTION_UPDATE,
                current: item.question,
            })
        }
    }

    const handleStartPosition = () => {
        // if (confirm(t('resetFormMessage'))) {
        const array = [...prevAnswers]
        const firstQuestion = array[0]
        setPrevAnswers([])
        dispatch({
            type: CURRENT_QUESTION_ACTION_UPDATE,
            current: firstQuestion.question,
        })
        // }
    }

    const handleNextClick = (questions: Node | null, current: Node, answer: AnswerOption, nextQuestion: Node) => {
        setPrevAnswers([...prevAnswers, { question: current, answer: answer }])
        setAnswer(null)
        setRadioValue('')

        dispatch({
            type: CURRENT_QUESTION_ACTION_UPDATE,
            current: nextQuestion,
        })
    }

    const inputType = () => {
        if (!current?.answer) {
            return <div>{t('invalidAnswer')}</div>
        }

        console.log(current.answer)

        switch (current?.answer.type) {
            case 'MULTIPLE_CHOICE':
                return (
                    <InputRadioButtons
                        key={'radio-' + current?.question.id}
                        name={'input-radio-buttons'}
                        selectedItem={radioValue}
                        options={current?.answer.answerOptions
                            .map((answerOption) => {
                                return {
                                    id: answerOption.id,
                                    value: answerOption.id,
                                    label: answerOption[('answerOption' + lang) as keyof AnswerOption] as string,
                                }
                            })
                            .sort((a, b) => {
                                if (current?.answer.sorting === 'ALPHABETICAL') {
                                    return a.label.localeCompare(b.label)
                                } else {
                                    return -1
                                }
                            })}
                        onChange={(selectedValue: string) => {
                            const selectedOption = current?.answer.answerOptions.find(
                                (n) => n.id.toString() === selectedValue
                            )

                            setAnswer(selectedOption)

                            if (selectedOption) {
                                setRadioValue(selectedOption.id)
                            }

                            const next: Node | undefined = current?.children?.find((node) => {
                                // console.log('node', node)
                                if (node.isGroup) {
                                    return node.answerOptionsIds.find((item) => item.toString() === selectedValue)
                                }
                                return node.parentAnswerOptionId?.toString() === selectedValue
                            })

                            if (next) {
                                setNextQuestion(next)
                            } else {
                                setNextQuestion(null)
                            }
                        }}
                    />
                )
            case 'SELECT':
                return (
                    <InputSelect
                        key={'select-' + current?.question.id}
                        firstOptionText={t('selectOptions')}
                        name={current?.question.id}
                        id={current?.question.id}
                        options={current.answer.answerOptions
                            .map((answerOption) => {
                                return {
                                    value: answerOption.id,
                                    text: answerOption[('answerOption' + lang) as keyof AnswerOption] as string,
                                }
                            })
                            .sort((a, b) => {
                                if (current?.answer.sorting === 'ALPHABETICAL') {
                                    return a.text.localeCompare(b.text)
                                } else {
                                    return -1
                                }
                            })}
                        changeSelected={(selectedValue: string) => {
                            const selectedOption = current?.answer.answerOptions.find(
                                (n) => n.id.toString() === selectedValue
                            )

                            setAnswer(selectedOption)

                            const next: Node | undefined = current?.children?.find((node) => {
                                if (node.isGroup) {
                                    return node.answerOptionsIds.find((item) => item.toString() === selectedValue)
                                }
                                return node.parentAnswerOptionId?.toString() === selectedValue
                            })
                            if (next) {
                                setNextQuestion(next)
                            } else {
                                setNextQuestion(null)
                            }
                        }}
                    />
                )
            case 'DROPDOWN':
                return (
                    <>
                        <label key={'label-' + current?.question.id} htmlFor={'select-' + current?.question.id}>
                            {t('selectOptions')}
                        </label>
                        <Autocomplete
                            key={'select-' + current?.question.id}
                            id={'select-' + current?.question.id}
                            noResultsText={t('autoCompleteNotFound')}
                            assistiveHintText={t('autoCompleteAssist')}
                            statusText={t('autoCompleteStatus')}
                            sort={false}
                            options={current.answer.answerOptions
                                .map((answerOption) => {
                                    return {
                                        value: answerOption.id,
                                        text: answerOption[('answerOption' + lang) as keyof AnswerOption] as string,
                                    }
                                })
                                .sort((a, b) => {
                                    if (current?.answer.sorting === 'ALPHABETICAL') {
                                        return a.text.localeCompare(b.text)
                                    } else {
                                        return -1
                                    }
                                })}
                            optionSelect={(option) => {
                                const selectedValue = option as { value: number; text: string }

                                const selectedOption = current?.answer.answerOptions.find(
                                    (n) => n.id.toString() === selectedValue?.value.toString()
                                )

                                setAnswer(selectedOption)

                                const next: Node | undefined = current?.children?.find((node) => {
                                    if (node.isGroup) {
                                        return node.answerOptionsIds.find(
                                            (item) => item.toString() === selectedValue?.value.toString()
                                        )
                                    }
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                    return node.parentAnswerOptionId?.toString() === selectedValue?.value.toString()
                                })
                                if (next) {
                                    setNextQuestion(next)
                                } else {
                                    setNextQuestion(null)
                                }
                            }}
                        />
                    </>
                )
        }
    }

    return (
        <div id="nwvw">
            <div className="component-wrapper">
                {!current && !results && <Loader />}
                {current && !results && (
                    <QuestionArea>
                        <ProgressBar
                            srText={'Progress'}
                            max={numberOfStepsLongestPath(current) + prevAnswers.length}
                            value={prevAnswers.length}
                        />
                        <div className="QuestionArea_form">
                            <h2>{current.question[('question' + lang) as keyof Question]}</h2>
                            {/*<Fieldset legend={current.question[('question' + lang) as keyof Question]} helpText={''}>*/}
                            {inputType()}
                            <div className="collapse-container">
                                {current.helpTexts &&
                                    current?.helpTexts.map((helpText, index) => (
                                        <Collapse
                                            id={'help-collapse-' + index}
                                            key={helpText.id}
                                            title={helpText[('title' + lang) as keyof HelpText]}
                                            content={helpText[('text' + lang) as keyof HelpText]}
                                            isOpen={false}
                                            isSmall={true}
                                        />
                                    ))}
                            </div>

                            {/*</Fieldset>*/}
                        </div>
                        <ButtonGroup result={false}>
                            {prevAnswers.length > 0 && !results && (
                                <Button
                                    text={t('previous')}
                                    asLink={true}
                                    inline={true}
                                    click={() => {
                                        handlePrevClick()
                                    }}
                                />
                            )}

                            <Button
                                className="QuestionArea_pullRight"
                                text={
                                    nextQuestion?.results && nextQuestion.results.length > 0
                                        ? t('showResult')
                                        : t('next')
                                }
                                disabled={!answer && !results}
                                inline={true}
                                click={
                                    !results && nextQuestion && answer
                                        ? () => {
                                              handleNextClick(questions, current, answer, nextQuestion)
                                          }
                                        : () => void true
                                }
                            />
                        </ButtonGroup>
                    </QuestionArea>
                )}

                {results && (
                    <ResultArea>
                        {current?.results.map((result, index) => (
                            <Collapse
                                id={'result-collapse-' + index}
                                key={result.id}
                                title={result[('title' + lang) as keyof Result]}
                                content={result[('text' + lang) as keyof Result]}
                                isOpen={current?.results.length <= 2}
                            />
                        ))}

                        {prevAnswers.length > 0 && (
                            <div className="resultsOverview">
                                <h3 className="title">{t('indicatedSituation')}</h3>
                                <table className="results">
                                    <thead>
                                        <tr>
                                            <th className="sr-only">{t('question')}</th>
                                            <th className="sr-only">{t('answer')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {prevAnswers.map((prevAnswers, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {
                                                        prevAnswers.question.question[
                                                            ('question' + lang) as keyof Question
                                                        ]
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        prevAnswers.answer[
                                                            ('answerOption' + lang) as keyof AnswerOption
                                                        ] as string
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <ButtonGroup result={true}>
                            <Button
                                text={t('fillInAgain')}
                                inline={true}
                                click={() => {
                                    handleStartPosition()
                                }}
                            />
                        </ButtonGroup>
                    </ResultArea>
                )}
            </div>
            <div style={{ visibility: 'hidden', display: 'none' }}>0.0.14</div>
        </div>
    )
}

export default MainPage
