import React from 'react'

export interface ButtonGroupProps {
    children?: React.ReactNode
    className?: string
    result?: boolean
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className = '', result = false }) => (
    <div className={`${result ? 'ResultsArea' : 'QuestionArea'}_buttonArea ${className}`}>{children}</div>
)

export default ButtonGroup
