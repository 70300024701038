import { Node } from '../state/types/Question'
import { load } from 'js-yaml'

const nodeLoader = async (url: string): Promise<Node | null> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
        })
        const file = await response.text()
        const x = load(file, {})
        return x as Promise<Node>
        // return (await response.json()) as Promise<Question>
    } catch (e) {
        console.log(e)
        return null
    }
}

export default nodeLoader
