import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import MainPage from './components/layouts/main/MainPage'
import { AppProvider } from './state/AppContext'
import './assets/css/App.scss'

interface appProps {
    questionfile: string
}

export default function App(props: appProps): JSX.Element {
    return (
        <AppProvider url={props.questionfile}>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                </Routes>
            </HashRouter>
        </AppProvider>
    )
}
